import React from 'react';

import { SCHEMA_TYPE_BOOLEAN, SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM } from '../../util/types';
import { constructQueryParamName } from './SearchPage.shared';
import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import BooleanFilter from './BooleanFilter/BooleanFilter';
import LocationFilter from './LocationFilter/LocationFilter';
import HourFilter from './HourFilter/HourFilter';
import BookingDateFilter from './BookingDateFilter/BookingDateFilter';

import css from './FilterComponent.module.css';

// Helper: get enumOptions in a format that works as query parameter
const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    idPrefix,
    config,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    marketplaceCurrency,
    intl,
    ...rest
  } = props;

  // Note: config can be either
  // - listingFields config or
  // - default filter config
  // They both have 'key' and 'schemaType' included.
  const { key, schemaType } = config;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${key.toLowerCase()}`;
  const name = key.replace(/\s+/g, '-').toLowerCase();

  // Default filters: price, keywords, dates
  switch (schemaType) {
    case 'price': {
      const { min, max, step } = config;
      return (
        <PriceFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.priceLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          min={min}
          max={max}
          step={step}
          marketplaceCurrency={marketplaceCurrency}
          {...rest}
        />
      );
    }
    case 'keywords':
      return (
        <KeywordFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.keywordsLabel' })}
          name={name}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          inputClassName={css.input}
          iconClassName={css.icon}
          wrapperClassName={css.root}
          {...rest}
        />
      );
    case 'dates': {
      const { dateRangeMode } = config;
      const isNightlyMode = dateRangeMode === 'night';

      return (
        <BookingDateFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.datesLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          minimumNights={isNightlyMode ? 1 : 0}
          inputClassName={css.input}
          iconClassName={css.icon}
          wrapperClassName={css.root}
          intl={intl}
          {...rest}
        />
      );
    }
    case 'location': {
      return (
        <LocationFilter
          id={componentId}
          initialValues={initialValues('location', liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          name={name}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          intl={intl}
          inputClassName={css.input}
          iconClassName={css.icon}
          wrapperClassName={css.root}
          {...rest}
        />
      );
    }
    default:
      break;
  }

  // Custom extended data filters
  switch (schemaType) {
    case SCHEMA_TYPE_BOOLEAN:
      const { scope, filterConfig = {} } = config;
      const queryParamNames = [constructQueryParamName(key, scope)];

      return (
        <BooleanFilter
          id={componentId}
          label={intl.formatMessage({ id: filterConfig.label })}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          name={name}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          intl={intl}
          icon={filterConfig?.icon}
          inputClassName={css.input}
          iconClassName={css.icon}
          wrapperClassName={css.root}
          {...rest}
        />
      );
    case SCHEMA_TYPE_ENUM: {
      const { scope, enumOptions, filterConfig = {} } = config;
      const queryParamNames = [constructQueryParamName(key, scope)];

      return config.key === 'standardPerformanceStartTime' ? (
        <HourFilter
          id={componentId}
          label={intl.formatMessage({ id: filterConfig.label })}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          name={name}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          intl={intl}
          inputClassName={css.input}
          iconClassName={css.icon}
          wrapperClassName={css.root}
          {...rest}
        />
      ) : filterConfig.filterType === 'SelectSingleFilter' ? (
        <SelectSingleFilter
          id={componentId}
          label={intl.formatMessage({ id: filterConfig.label })}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          options={createFilterOptions(enumOptions)}
          {...rest}
        />
      ) : (
        <SelectMultipleFilter
          id={componentId}
          label={filterConfig.label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          schemaType={schemaType}
          icon={filterConfig?.icon}
          inputClassName={css.input}
          iconClassName={css.icon}
          wrapperClassName={css.root}
          {...rest}
        />
      );
    }
    case SCHEMA_TYPE_MULTI_ENUM: {
      let { scope, enumOptions, filterConfig = {} } = config;
      const { searchMode } = filterConfig;
      const queryParamNames = [constructQueryParamName(key, scope)];
      if (config.key === 'addonsCategories')
        enumOptions = enumOptions.map(option => ({
          ...option,
          label: intl.formatMessage({ id: option.label }),
        }));

      return (
        <SelectMultipleFilter
          id={componentId}
          label={intl.formatMessage({ id: filterConfig.label })}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          schemaType={schemaType}
          searchMode={searchMode}
          icon={filterConfig?.icon}
          inputClassName={css.input}
          iconClassName={css.icon}
          wrapperClassName={css.root}
          {...rest}
        />
      );
    }
    default:
      return null;
  }
};

export default FilterComponent;
