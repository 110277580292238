import React from 'react';
import FilterPopup from '../FilterPopup/FilterPopup';
import classNames from 'classnames';

import css from './BooleanFilter.module.css';
import FilterPlain from '../FilterPlain/FilterPlain';
import { Field } from 'react-final-form';

const FieldBoolean = props => {
  const { label, inputClassName, ...rest } = props;

  return (
    <Field {...rest}>
      {fieldProps => {
        const { input, id } = fieldProps;
        const { onChange, value, ...restInputProps } = input;

        return (
          <span className={classNames(inputClassName, css.root)}>
            <label htmlFor={id} className={css.label}>
              {label}
            </label>
            <input
              className={css.input}
              id={id}
              type="checkbox"
              {...restInputProps}
              onChange={e => {
                input.onChange(e.target.checked);
              }}
              checked={value}
            />
          </span>
        );
      }}
    </Field>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const BooleanFilter = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    options,
    initialValues,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    schemaType,
    searchMode,
    intl,
    showAsPopup,
    icon,
    iconClassName,
    inputClassName,
    wrapperClassName,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const queryParamName = getQueryParamName(queryParamNames);
  const hasInitialValues = !!initialValues && !!initialValues[queryParamName];

  const handleSubmit = values => {
    onSubmit({
      [queryParamName]: values && values[queryParamName] ? values[queryParamName] : null,
    });
  };

  return showAsPopup ? (
    <FilterPopup
      className={classes}
      rootClassName={rootClassName}
      popupClassName={css.popupSize}
      name={name}
      isSelected={hasInitialValues}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
      {...rest}
    >
      <div className={classNames(css.root, wrapperClassName)}>
        <span className={iconClassName}>{icon}</span>
        <FieldBoolean
          id={`${id}.checkbox`}
          name={queryParamName}
          intl={intl}
          label={label}
          inputClassName={inputClassName}
        />
      </div>
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={label}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
      {...rest}
    >
      <div className={classNames(css.root, wrapperClassName)}>
        <span className={iconClassName}>{icon}</span>
        <FieldBoolean
          id={`${id}.checkbox`}
          name={queryParamName}
          intl={intl}
          label={label}
          inputClassName={inputClassName}
        />
      </div>
    </FilterPlain>
  );
};

export default BooleanFilter;
