import React from 'react';
import FilterPopup from '../FilterPopup/FilterPopup';
import classNames from 'classnames';
import FilterPlain from '../FilterPlain/FilterPlain';
import { Field } from 'react-final-form';
import { IconLocation, LocationAutocompleteInput, Tooltip } from '../../../components';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { parse } from '../../../util/urlHelpers';
import { BOOKING_LOCATION_TYPES } from '../../../constants/booking';
import { getAreaFromPostalCode } from '../../../util/search';

const identity = v => v;

const LocationSearchField = props => {
  const {
    intl,
    isMobile,
    inputRef,
    onLocationChange,
    inputClassName,
    iconClassName,
    wrapperClassName,
  } = props;

  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;
        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange?.(value);
        };

        return (
          <Tooltip
            anchorSelect="#location-filter"
            openEvents={{ mouseenter: true }}
            closeEvents={{ click: true, mouseleave: true, blur: true }}
            trigger={
              <LocationAutocompleteInput
                id="location-filter"
                className={wrapperClassName}
                iconClassName={iconClassName}
                icon={<IconLocation />}
                inputClassName={inputClassName}
                placeholder={intl.formatMessage({ id: 'General.venueLocation' })}
                closeOnBlur={!isMobile}
                inputRef={inputRef}
                input={{ ...restInput, onChange: searchOnChange }}
                meta={meta}
                types={BOOKING_LOCATION_TYPES}
                usePredictionAddress
              />
            }
          >
            {intl.formatMessage({ id: 'General.venueLocation' })}
          </Tooltip>
        );
      }}
    />
  );
};

const LocationFilter = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    options,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    schemaType,
    searchMode,
    intl,
    showAsPopup,
    ...rest
  } = props;

  const classes = classNames(rootClassName, className);

  const location = useLocation();

  const { address, origin } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const initialValues = (address && {
    location: {
      search: address,
      selectedPlace: { address, origin },
    },
  }) || { location: {} };

  const handleSubmit = values => {
    const { location } = values;

    if (!location?.search)
      return onSubmit({
        address: null,
        origin: null,
        pub_serviceableLocations: null,
        postalCode: null,
        city: null,
        streetName: null,
        venueName: null,
      });
    if (!location?.selectedPlace) return;

    const { search, selectedPlace } = location || {};
    const { origin, postalCode, name, line1, city } = selectedPlace;

    const splitPostalCode = getAreaFromPostalCode(postalCode);

    const serviceableLocationsMaybe = splitPostalCode
      ? {
          pub_serviceableLocations: splitPostalCode,
        }
      : {
          pub_serviceableLocations: null,
        };

    onSubmit({
      address: search,
      origin,
      venueName: name,
      postalCode,
      streetName: line1,
      city,
      ...serviceableLocationsMaybe,
    });
  };

  return showAsPopup ? (
    <FilterPopup
      {...rest}
      className={classes}
      rootClassName={rootClassName}
      name={name}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
    >
      <LocationSearchField intl={intl} {...rest} />
    </FilterPopup>
  ) : (
    <FilterPlain
      {...rest}
      className={className}
      rootClassName={rootClassName}
      label={label}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
    >
      <LocationSearchField intl={intl} {...rest} />
    </FilterPlain>
  );
};

export default LocationFilter;
