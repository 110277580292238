import React from 'react';
import FilterPopup from '../FilterPopup/FilterPopup';
import classNames from 'classnames';
import FilterPlain from '../FilterPlain/FilterPlain';
import { FieldHourInput, IconClock } from '../../../components';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../util/urlHelpers';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const HourFilter = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    options,
    contentPlacementOffset,
    onSubmit,
    queryParamNames,
    schemaType,
    searchMode,
    intl,
    showAsPopup,
    iconClassName,
    inputClassName,
    wrapperClassName,
    ...rest
  } = props;

  const classes = classNames(rootClassName, className);
  const queryParamName = getQueryParamName(queryParamNames);
  const location = useLocation();
  const { pub_startTime } = parse(location?.search);
  const hasInitialValues = !!pub_startTime;

  const handleSubmit = values => {
    onSubmit({
      pub_startTime: values && values[queryParamName] ? values[queryParamName] : null,
    });
  };

  return showAsPopup ? (
    <FilterPopup
      className={classes}
      rootClassName={rootClassName}
      name={name}
      isSelected={hasInitialValues}
      id={`${id}.popup`}
      showAsPopup
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize={false}
      {...rest}
      initialValues={{ [queryParamName]: pub_startTime }}
    >
      <FieldHourInput
        id={`${id}.hour`}
        name={queryParamName}
        inputClassName={inputClassName}
        className={wrapperClassName}
        icon={<IconClock className={iconClassName} />}
        placeholder={label}
      />
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={label}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize={false}
      {...rest}
      initialValues={{ [queryParamName]: pub_startTime }}
    >
      <FieldHourInput
        id={`${id}.hour`}
        name={queryParamName}
        inputClassName={inputClassName}
        className={wrapperClassName}
        icon={<IconClock className={iconClassName} />}
        placeholder={label}
      />
    </FilterPlain>
  );
};

export default HourFilter;
